import { default as initixJ7fHbZPxMeta } from "/home/circleci/project/pages/auth/init.vue?macro=true";
import { default as indexbVK8ERtDnaMeta } from "/home/circleci/project/pages/index.vue?macro=true";
import { default as graph3JLe9T3aVXMeta } from "/home/circleci/project/pages/report/base/graph.vue?macro=true";
import { default as indexekAf49YbrlMeta } from "/home/circleci/project/pages/report/base/index.vue?macro=true";
import { default as listsUcd9hgM6oMeta } from "/home/circleci/project/pages/report/base/list.vue?macro=true";
import { default as graphKnbcyUiY4AMeta } from "/home/circleci/project/pages/report/cumulative/daily/graph.vue?macro=true";
import { default as listkMtnzXi9fJMeta } from "/home/circleci/project/pages/report/cumulative/daily/list.vue?macro=true";
import { default as indexC4nJ1kXlUeMeta } from "/home/circleci/project/pages/report/cumulative/index.vue?macro=true";
import { default as graphQIjhBKZ730Meta } from "/home/circleci/project/pages/report/cumulative/monthly/graph.vue?macro=true";
import { default as listGTL8SzDDXPMeta } from "/home/circleci/project/pages/report/cumulative/monthly/list.vue?macro=true";
import { default as graphA8wdQY1JmMMeta } from "/home/circleci/project/pages/report/period/daily/graph.vue?macro=true";
import { default as listClPkYuHyKpMeta } from "/home/circleci/project/pages/report/period/daily/list.vue?macro=true";
import { default as indexrFAln8LU76Meta } from "/home/circleci/project/pages/report/period/index.vue?macro=true";
import { default as grapheFVxI9BDaqMeta } from "/home/circleci/project/pages/report/period/monthly/graph.vue?macro=true";
import { default as list4nQj0uMjVLMeta } from "/home/circleci/project/pages/report/period/monthly/list.vue?macro=true";
import { default as listoreDLV5sxbMeta } from "/home/circleci/project/pages/report/promotion/flyer/[id]/daily/list.vue?macro=true";
import { default as summary7lJGAbjSFCMeta } from "/home/circleci/project/pages/report/promotion/flyer/[id]/daily/summary.vue?macro=true";
import { default as graphZmQeWaXZqNMeta } from "/home/circleci/project/pages/report/promotion/flyer/daily/graph.vue?macro=true";
import { default as listiH40eBcr4yMeta } from "/home/circleci/project/pages/report/promotion/flyer/daily/list.vue?macro=true";
import { default as indexz3T1pfxUwkMeta } from "/home/circleci/project/pages/report/promotion/flyer/index.vue?macro=true";
import { default as graphPsFOFcKiY7Meta } from "/home/circleci/project/pages/report/promotion/flyer/monthly/graph.vue?macro=true";
import { default as listEOSIejZdvOMeta } from "/home/circleci/project/pages/report/promotion/flyer/monthly/list.vue?macro=true";
import { default as selectionpXwGP8BGhoMeta } from "/home/circleci/project/pages/report/promotion/flyer/selection.vue?macro=true";
import { default as graphumrXZStHTxMeta } from "/home/circleci/project/pages/report/section/graph.vue?macro=true";
import { default as index3ErWyJVKCRMeta } from "/home/circleci/project/pages/report/section/index.vue?macro=true";
import { default as listzW30XELKKdMeta } from "/home/circleci/project/pages/report/section/list.vue?macro=true";
import { default as index0cTIgbeA3dMeta } from "/home/circleci/project/pages/summary/index.vue?macro=true";
export default [
  {
    name: initixJ7fHbZPxMeta?.name ?? "auth-init",
    path: initixJ7fHbZPxMeta?.path ?? "/auth/init",
    meta: initixJ7fHbZPxMeta || {},
    alias: initixJ7fHbZPxMeta?.alias || [],
    redirect: initixJ7fHbZPxMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/auth/init.vue").then(m => m.default || m)
  },
  {
    name: indexbVK8ERtDnaMeta?.name ?? "index",
    path: indexbVK8ERtDnaMeta?.path ?? "/",
    meta: indexbVK8ERtDnaMeta || {},
    alias: indexbVK8ERtDnaMeta?.alias || [],
    redirect: indexbVK8ERtDnaMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/index.vue").then(m => m.default || m)
  },
  {
    name: graph3JLe9T3aVXMeta?.name ?? "report-base-graph",
    path: graph3JLe9T3aVXMeta?.path ?? "/report/base/graph",
    meta: graph3JLe9T3aVXMeta || {},
    alias: graph3JLe9T3aVXMeta?.alias || [],
    redirect: graph3JLe9T3aVXMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/base/graph.vue").then(m => m.default || m)
  },
  {
    name: indexekAf49YbrlMeta?.name ?? "report-base",
    path: indexekAf49YbrlMeta?.path ?? "/report/base",
    meta: indexekAf49YbrlMeta || {},
    alias: indexekAf49YbrlMeta?.alias || [],
    redirect: indexekAf49YbrlMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/base/index.vue").then(m => m.default || m)
  },
  {
    name: listsUcd9hgM6oMeta?.name ?? "report-base-list",
    path: listsUcd9hgM6oMeta?.path ?? "/report/base/list",
    meta: listsUcd9hgM6oMeta || {},
    alias: listsUcd9hgM6oMeta?.alias || [],
    redirect: listsUcd9hgM6oMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/base/list.vue").then(m => m.default || m)
  },
  {
    name: graphKnbcyUiY4AMeta?.name ?? "report-cumulative-daily-graph",
    path: graphKnbcyUiY4AMeta?.path ?? "/report/cumulative/daily/graph",
    meta: graphKnbcyUiY4AMeta || {},
    alias: graphKnbcyUiY4AMeta?.alias || [],
    redirect: graphKnbcyUiY4AMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/cumulative/daily/graph.vue").then(m => m.default || m)
  },
  {
    name: listkMtnzXi9fJMeta?.name ?? "report-cumulative-daily-list",
    path: listkMtnzXi9fJMeta?.path ?? "/report/cumulative/daily/list",
    meta: listkMtnzXi9fJMeta || {},
    alias: listkMtnzXi9fJMeta?.alias || [],
    redirect: listkMtnzXi9fJMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/cumulative/daily/list.vue").then(m => m.default || m)
  },
  {
    name: indexC4nJ1kXlUeMeta?.name ?? "report-cumulative",
    path: indexC4nJ1kXlUeMeta?.path ?? "/report/cumulative",
    meta: indexC4nJ1kXlUeMeta || {},
    alias: indexC4nJ1kXlUeMeta?.alias || [],
    redirect: indexC4nJ1kXlUeMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/cumulative/index.vue").then(m => m.default || m)
  },
  {
    name: graphQIjhBKZ730Meta?.name ?? "report-cumulative-monthly-graph",
    path: graphQIjhBKZ730Meta?.path ?? "/report/cumulative/monthly/graph",
    meta: graphQIjhBKZ730Meta || {},
    alias: graphQIjhBKZ730Meta?.alias || [],
    redirect: graphQIjhBKZ730Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/cumulative/monthly/graph.vue").then(m => m.default || m)
  },
  {
    name: listGTL8SzDDXPMeta?.name ?? "report-cumulative-monthly-list",
    path: listGTL8SzDDXPMeta?.path ?? "/report/cumulative/monthly/list",
    meta: listGTL8SzDDXPMeta || {},
    alias: listGTL8SzDDXPMeta?.alias || [],
    redirect: listGTL8SzDDXPMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/cumulative/monthly/list.vue").then(m => m.default || m)
  },
  {
    name: graphA8wdQY1JmMMeta?.name ?? "report-period-daily-graph",
    path: graphA8wdQY1JmMMeta?.path ?? "/report/period/daily/graph",
    meta: graphA8wdQY1JmMMeta || {},
    alias: graphA8wdQY1JmMMeta?.alias || [],
    redirect: graphA8wdQY1JmMMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/period/daily/graph.vue").then(m => m.default || m)
  },
  {
    name: listClPkYuHyKpMeta?.name ?? "report-period-daily-list",
    path: listClPkYuHyKpMeta?.path ?? "/report/period/daily/list",
    meta: listClPkYuHyKpMeta || {},
    alias: listClPkYuHyKpMeta?.alias || [],
    redirect: listClPkYuHyKpMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/period/daily/list.vue").then(m => m.default || m)
  },
  {
    name: indexrFAln8LU76Meta?.name ?? "report-period",
    path: indexrFAln8LU76Meta?.path ?? "/report/period",
    meta: indexrFAln8LU76Meta || {},
    alias: indexrFAln8LU76Meta?.alias || [],
    redirect: indexrFAln8LU76Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/period/index.vue").then(m => m.default || m)
  },
  {
    name: grapheFVxI9BDaqMeta?.name ?? "report-period-monthly-graph",
    path: grapheFVxI9BDaqMeta?.path ?? "/report/period/monthly/graph",
    meta: grapheFVxI9BDaqMeta || {},
    alias: grapheFVxI9BDaqMeta?.alias || [],
    redirect: grapheFVxI9BDaqMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/period/monthly/graph.vue").then(m => m.default || m)
  },
  {
    name: list4nQj0uMjVLMeta?.name ?? "report-period-monthly-list",
    path: list4nQj0uMjVLMeta?.path ?? "/report/period/monthly/list",
    meta: list4nQj0uMjVLMeta || {},
    alias: list4nQj0uMjVLMeta?.alias || [],
    redirect: list4nQj0uMjVLMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/period/monthly/list.vue").then(m => m.default || m)
  },
  {
    name: listoreDLV5sxbMeta?.name ?? "report-promotion-flyer-id-daily-list",
    path: listoreDLV5sxbMeta?.path ?? "/report/promotion/flyer/:id()/daily/list",
    meta: listoreDLV5sxbMeta || {},
    alias: listoreDLV5sxbMeta?.alias || [],
    redirect: listoreDLV5sxbMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/promotion/flyer/[id]/daily/list.vue").then(m => m.default || m)
  },
  {
    name: summary7lJGAbjSFCMeta?.name ?? "report-promotion-flyer-id-daily-summary",
    path: summary7lJGAbjSFCMeta?.path ?? "/report/promotion/flyer/:id()/daily/summary",
    meta: summary7lJGAbjSFCMeta || {},
    alias: summary7lJGAbjSFCMeta?.alias || [],
    redirect: summary7lJGAbjSFCMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/promotion/flyer/[id]/daily/summary.vue").then(m => m.default || m)
  },
  {
    name: graphZmQeWaXZqNMeta?.name ?? "report-promotion-flyer-daily-graph",
    path: graphZmQeWaXZqNMeta?.path ?? "/report/promotion/flyer/daily/graph",
    meta: graphZmQeWaXZqNMeta || {},
    alias: graphZmQeWaXZqNMeta?.alias || [],
    redirect: graphZmQeWaXZqNMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/promotion/flyer/daily/graph.vue").then(m => m.default || m)
  },
  {
    name: listiH40eBcr4yMeta?.name ?? "report-promotion-flyer-daily-list",
    path: listiH40eBcr4yMeta?.path ?? "/report/promotion/flyer/daily/list",
    meta: listiH40eBcr4yMeta || {},
    alias: listiH40eBcr4yMeta?.alias || [],
    redirect: listiH40eBcr4yMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/promotion/flyer/daily/list.vue").then(m => m.default || m)
  },
  {
    name: indexz3T1pfxUwkMeta?.name ?? "report-promotion-flyer",
    path: indexz3T1pfxUwkMeta?.path ?? "/report/promotion/flyer",
    meta: indexz3T1pfxUwkMeta || {},
    alias: indexz3T1pfxUwkMeta?.alias || [],
    redirect: indexz3T1pfxUwkMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/promotion/flyer/index.vue").then(m => m.default || m)
  },
  {
    name: graphPsFOFcKiY7Meta?.name ?? "report-promotion-flyer-monthly-graph",
    path: graphPsFOFcKiY7Meta?.path ?? "/report/promotion/flyer/monthly/graph",
    meta: graphPsFOFcKiY7Meta || {},
    alias: graphPsFOFcKiY7Meta?.alias || [],
    redirect: graphPsFOFcKiY7Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/promotion/flyer/monthly/graph.vue").then(m => m.default || m)
  },
  {
    name: listEOSIejZdvOMeta?.name ?? "report-promotion-flyer-monthly-list",
    path: listEOSIejZdvOMeta?.path ?? "/report/promotion/flyer/monthly/list",
    meta: listEOSIejZdvOMeta || {},
    alias: listEOSIejZdvOMeta?.alias || [],
    redirect: listEOSIejZdvOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/promotion/flyer/monthly/list.vue").then(m => m.default || m)
  },
  {
    name: selectionpXwGP8BGhoMeta?.name ?? "report-promotion-flyer-selection",
    path: selectionpXwGP8BGhoMeta?.path ?? "/report/promotion/flyer/selection",
    meta: selectionpXwGP8BGhoMeta || {},
    alias: selectionpXwGP8BGhoMeta?.alias || [],
    redirect: selectionpXwGP8BGhoMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/promotion/flyer/selection.vue").then(m => m.default || m)
  },
  {
    name: graphumrXZStHTxMeta?.name ?? "report-section-graph",
    path: graphumrXZStHTxMeta?.path ?? "/report/section/graph",
    meta: graphumrXZStHTxMeta || {},
    alias: graphumrXZStHTxMeta?.alias || [],
    redirect: graphumrXZStHTxMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/section/graph.vue").then(m => m.default || m)
  },
  {
    name: index3ErWyJVKCRMeta?.name ?? "report-section",
    path: index3ErWyJVKCRMeta?.path ?? "/report/section",
    meta: index3ErWyJVKCRMeta || {},
    alias: index3ErWyJVKCRMeta?.alias || [],
    redirect: index3ErWyJVKCRMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/section/index.vue").then(m => m.default || m)
  },
  {
    name: listzW30XELKKdMeta?.name ?? "report-section-list",
    path: listzW30XELKKdMeta?.path ?? "/report/section/list",
    meta: listzW30XELKKdMeta || {},
    alias: listzW30XELKKdMeta?.alias || [],
    redirect: listzW30XELKKdMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/report/section/list.vue").then(m => m.default || m)
  },
  {
    name: index0cTIgbeA3dMeta?.name ?? "summary",
    path: index0cTIgbeA3dMeta?.path ?? "/summary",
    meta: index0cTIgbeA3dMeta || {},
    alias: index0cTIgbeA3dMeta?.alias || [],
    redirect: index0cTIgbeA3dMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/summary/index.vue").then(m => m.default || m)
  }
]