import VueGtag from 'vue-gtag'

export default defineNuxtPlugin(({ vueApp }) => {
  const router = useRouter()
  const runtimeConfig = useRuntimeConfig()
  const { XORDER_ENV, GTAG_ID } = runtimeConfig.public

  vueApp.use(
    VueGtag,
    {
      appName: 'xorder-dashboard-supplier',
      pageTrackerScreenviewEnabled: XORDER_ENV !== 'local',
      config: { id: GTAG_ID },
    },
    router
  )
})
