import revive_payload_client_4sVQNw7RlN from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/circleci/project/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/circleci/project/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/circleci/project/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import useDirectives_W0tXrzvhuP from "/home/circleci/project/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.mjs";
import createBootstrap_ywvW1KIeJ9 from "/home/circleci/project/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.mjs";
import plugin_AOuQ1DYzjk from "/home/circleci/project/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_client_shVUlIjFLk from "/home/circleci/project/plugins/sentry.client.ts";
import vee_validate_KcKlKmvCrJ from "/home/circleci/project/plugins/vee-validate.ts";
import vue_gtag_client_CYt6toK02h from "/home/circleci/project/plugins/vue-gtag.client.ts";
import vue_observe_visibility_eJ2HjGsWX9 from "/home/circleci/project/plugins/vue-observe-visibility.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  useDirectives_W0tXrzvhuP,
  createBootstrap_ywvW1KIeJ9,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  sentry_client_shVUlIjFLk,
  vee_validate_KcKlKmvCrJ,
  vue_gtag_client_CYt6toK02h,
  vue_observe_visibility_eJ2HjGsWX9
]