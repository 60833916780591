import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(({ vueApp }) => {
  const config = useRuntimeConfig()
  const environment = config.public.SENTRY_ENVIRONMENT as
    | 'local'
    | 'production'
    | 'staging'
  const projectUrl = config.public.PROJECT_URL as string
  const dsn = config.public.SENTRY_DSN as string
  const release = config.public.SENTRY_RELEASE as string

  const router = useRouter()

  Sentry.init({
    app: [vueApp],
    dsn,
    environment,
    release,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: [projectUrl],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  })
})
