import { differenceInDays, parseISO } from 'date-fns'
import { ErrorMessage, Field, Form, defineRule } from 'vee-validate'

export default defineNuxtPlugin((nuxtApp) => {
  defineRule(
    'date_within_range',
    ([start, end]: string[], value: number, { field }) => {
      const startDate = parseISO(start)
      const endDate = parseISO(end)

      if (differenceInDays(endDate, startDate) <= value) {
        return true
      }

      return `${field}は${value}日以内である必要があります`
    }
  )

  defineRule(
    'date_beyond_range',
    ([start, end]: string[], value: number, { field }) => {
      const startDate = parseISO(start)
      const endDate = parseISO(end)

      if (differenceInDays(endDate, startDate) >= value) {
        return true
      }

      return `${field}は${value}日以上離れている必要があります`
    }
  )

  nuxtApp.vueApp.component('ValidationForm', Form)
  nuxtApp.vueApp.component('ValidationField', Field)
  nuxtApp.vueApp.component('ValidationErrorMessage', ErrorMessage)
})

// VeeValidateの型定義を拡張
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    ValidationForm: typeof Form
    ValidationField: typeof Field
    ValidationErrorMessage: typeof ErrorMessage
  }
}
