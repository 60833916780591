import $localForage from 'localforage'

export default defineNuxtRouteMiddleware(() => {
  if (!$localForage) {
    console.warn('localForage undefined')
    return
  }

  reload().catch((e) => {
    console.warn(e)
  })
})

const reload = async (): Promise<void> => {
  // キャッシュを無効にするためハッシュを付けて確認する
  const hash = new Date().getTime()
  const runtimeConfig = useRuntimeConfig()

  const api = $fetch.create<Response>({
    baseURL: runtimeConfig.public.PROJECT_URL,
  })
  const response = await api<{ revision: string }>('/revision.json', {
    params: { timestamp: hash },
  })

  // データ不正の場合
  if (!response || !response.revision)
    throw new Error('revisionが見つかりません')

  const savedRevision = await $localForage.getItem('revision')
  $localForage.setItem('revision', response.revision)

  // 初回のrevisionチェックの為、リロードしない
  if (!savedRevision) return
  // 変更がない場合はリロードしない
  if (savedRevision === response.revision) return

  location.reload()
}
